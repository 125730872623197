<template>
  <NavMenu title="Genevieve Rose Vickers" />
  <router-view v-slot="{ Component }">
    <transition name="slide-fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
import NavMenu from "@/components/NavMenu.vue";

export default {
  components: {
    NavMenu
  }
};
</script>
