<template>
  <div class="container clearfix">
    <div class="float-right">
      <img class="image" src="@/assets/home.jpg" />
    </div>
    <h1 class="emboss">Stories for One More Step</h1>
    <p>
      Heya! I’m Genevieve, and I write stuff. That stuff is for those who need
      shelter—for those who need to see themselves somewhere to find the
      strength to keep going. Also for the fun of watching people I made up make
      dumb decisions. Check out my debut superhero novel, <em>Amethyst</em>, an
      ode to self-love in the face of self-doubt,
      <a
        href="https://www.amazon.com/Amethyst-Dawnbringer-Genevieve-Rose-Vickers-ebook/dp/B08VGXB3DL"
        target="_blank"
        >here.</a
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "Main",
  props: {
    title: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (min-width: 601px) {
  p {
    padding-top: 30px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
  }

  h1 {
    margin-top: 120px;
    margin-left: 50px;
    text-align: center;
    line-height: 50px;
  }

  .image {
    margin-top: 30px;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 50px;
  }
}
</style>
