<template>
  <div id="nav" class="emboss">
    <h2>{{ title }}</h2>
    <ul>
      <li>
        <router-link to="/">Home</router-link>
      </li>
      <li>
        <router-link to="/about">About</router-link>
      </li>
      <li>
        <router-link to="/books">Books</router-link>
      </li>
      <li>
        <router-link to="/contact">Contact</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "NavMenu",
  props: {
    title: String
  }
};
</script>

<style>
@media (max-width: 600px) {
  #nav h2 {
    text-align: center;
    padding-top: 30px;
    padding-left: 10px;
  }

  #nav {
    transform: rotate(-5deg);
    width: 400px;
    text-align: left;
  }
}

@media (min-width: 601px) {
  #nav h2 {
    text-align: left;
    padding-left: 40px;
  }

  #nav {
    transform: rotate(-15deg);
    width: 800px;
    text-align: left;
  }
}

#nav ul {
  list-style-type: none;
  overflow: hidden;
  background-color: white;
}

#nav li a {
  display: block;
  float: left;
  color: rbg(147, 166, 188);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  text-decoration: none;
  transition-duration: 0.5s;
}

#nav li a:hover {
  background-color: gray;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: rgb(197, 34, 34);
}
</style>
