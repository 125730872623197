<template>
  <div class="home">
    <Main msg="Genevieve Rose Vickers" />
  </div>
</template>

<script>
import Main from "@/components/Main.vue";

export default {
  name: "Home",
  components: {
    Main
  }
};
</script>
