<template>
  <div>
    <desktop> </desktop>
  </div>
</template>

<script>
import Desktop from "./views/Desktop.vue";

export default {
  components: {
    Desktop
  },
  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    }
  }
};
</script>

<style>
body {
  background-image: url("./assets/chalkboard.jpg");
  background-repeat: repeat;
  margin: 0;
  padding: 0;
  text-align: center;
}

p {
  color: white;
}

@font-face {
  font-family: "Emboss";
  src: local("Emboss"), url("./fonts/Emboss/embosst3.ttf") format("truetype");
}

.emboss {
  font-family: Emboss, Helvetica, Arial;
  color: white;
}

.container {
  width: 100%;
}

.clearfix {
  overflow: auto;
}

@media (max-width: 600px) {
  .float-left {
    padding: 20px;
  }

  .float-right {
    padding: 20px;
  }

  p {
    padding: 10px;
    margin: 10px;
  }

  h1 {
    line-height: 50px;
  }

  .image {
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: auto;
  }
}

@media (min-width: 601px) {
  .float-left {
    float: left;
    padding: 50px;
  }

  .float-right {
    float: right;
    padding: 50px;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-family: Tahoma, Verdana, Geneva, sans-serif;
  text-align: justify;
  font-size: 20px;
  line-height: 30px;
}

/*** TRANSITIONS ***/

.fade-enter {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}

.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter {
  transform: translateX(-10px);
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.2s ease;
}

.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

/*** ANCHORS ***/

a:link {
  color: rgb(197, 34, 34);
}

a:visited {
  color: rgb(100, 34, 34);
}
</style>
